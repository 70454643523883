import axios from 'axios';

const api = axios.create({
  baseURL: process.env.GATSBY_CLIENT_API_URL,
});

export const productApi = axios.create({
  baseURL: process.env.GATSBY_WORDPRESS_API_URL,
});

export const chargeApi = axios.create({
  baseURL: process.env.GATSBY_CHARGE_API_URL,
});

export default api;
